import { CategoryTagsResponse } from 'libs/dt-types/response-types/category-tags';
import { PurcahsedItemResponse } from 'libs/dt-types/response-types/item';
import { requestor } from 'libs/helpers/api/requestor';

export interface advancedQueryType {
  tag?: string | null;
  page?: number;
  limit?: number;
  style?: string | null;
  theme?: string | null;
  topic?: string | null;
  sort_by?: string | null;
  category: string;
  industry?: string | null;
  name?: string | null;
}

const itemTypes: any = {
  free: 'free',
  premium: 'premium',
  pro: 'pro',
};

export const createQuery = (query: {
  slug: string;
  tag?: string;
  page?: number;
  limit?: number;
}) => {
  const firstSlug = query.slug.split('-')?.[0].toLowerCase();
  const remainingSlug = query.slug.split('-').slice(1).join('-');
  const type = itemTypes[firstSlug] as unknown as string;
  let url = `category=${type ? remainingSlug : query.slug}`;

  if (type) {
    url += `&type=${type}`;
  }

  if (query.tag) {
    url += `&tag=${query.tag}`;
  }

  url += `&page=${query.page ?? 1}`;
  url += `&limit=${query.limit ?? 15}`;

  return url;
};

export const createAdvancedQuery = (query: advancedQueryType) => {
  let url = '';
  let type = '';
  const firstSlug = query?.category?.split('-')?.[0].toLowerCase();
  const remainingSlug = query?.category?.split('-').slice(1).join('-');

  type = itemTypes[firstSlug] as unknown as string;

  url = `category=${type ? remainingSlug : query.category || 'all categories'}`;

  if (query?.name) {
    const firstSlug = query.name.split('-')?.[0].toLowerCase();
    const remainingSlug = query.name.split('-').slice(1).join('-');

    type = itemTypes[firstSlug] as unknown as string;
    url += `&name=${type ? remainingSlug : query.name}`;
  }

  if (type) {
    url += `&type=${type}`;
  }

  if (query?.style) {
    url += `&style=${query.style}`;
  }

  if (query?.theme) {
    url += `&theme=${query.theme}`;
  }

  if (query?.sort_by) {
    url += `&sort_by=${query.sort_by}`;
  }

  if (query?.industry) {
    url += `&industry=${query.industry}`;
  }

  if (query?.tag) {
    url += `&tag=${query.tag}`;
  }

  url += `&page=${query?.page ?? 1}`;
  url += `&size=${query?.limit ?? 12}`;

  return url;
};

export const getCategoryDataBySlugandTag = async (
  slug: string,
  tag?: string,
  page?: number,
  limit?: number,
  cookieStore?: any
) => {
  if (cookieStore) {
    requestor.setCookiesProvider(cookieStore);
  }

  const query = createQuery({ slug, tag: tag, page: page, limit: limit });

  try {
    const response = await requestor.get<any>(
      `${process.env.API_BASE_URL as string}/listing/items?${query}`
    );
    const { data }: any = response ?? {};

    if (!data) {
      throw new Error('No data found');
    }

    const updatedData = await checkItemPurchased(data, cookieStore);

    return updatedData;
  } catch (error: any) {
    return getAdvancedSearchResults(slug ?? '', page, 15, cookieStore);
  }
};

export const getSimilarItems = async (
  id: string,
  categoryId: string,
  cookieStore?: any
) => {
  if (cookieStore) {
    requestor.setCookiesProvider(cookieStore);
  }

  let url = `${
    process.env.API_BASE_URL as string
  }/listing/items/similar-items/${id}`;

  if (categoryId) {
    url = url + `?categoryId=${categoryId}`;
  }

  const response = await requestor.get<any>(url);
  let data: any = response.data;

  let purchasedItemIds = [];

  if (cookieStore?.get('accesstoken')) {
    purchasedItemIds = await getPurchasedItemHistory(cookieStore);
  }

  data = data.map((item: PurcahsedItemResponse) => {
    const isPurchased = purchasedItemIds?.includes(item.id);

    return { ...item, isPurchased };
  });

  return data;
};

const getAdvancedSearchResults = async (
  slug: string,
  page?: number,
  limit?: number,
  cookieStore?: any
) => {
  if (cookieStore) {
    requestor.setCookiesProvider(cookieStore);
  }

  const response = await requestor.get<any>(
    `/listing-v2/items?name=${slug}&limit=${limit}&page=${page}`
  );
  const { data }: CategoryTagsResponse = response;

  const updatedData = await checkItemPurchased(data, cookieStore);

  return updatedData;
};

export const getPurchasedItemHistory = async (cookieStore: any) => {
  try {
    if (cookieStore) {
      requestor.setCookiesProvider(cookieStore);
    }

    const accesstoken = cookieStore?.get('accesstoken')?.value as string;

    const response = await requestor.get<any>(
      `${process.env.API_BASE_URL}/orders-service/order/user-purchase-history`,
      {
        headers: { accesstoken },
      }
    );

    return response?.itemIds;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export const checkItemPurchased = async (data: any, cookieStore: any) => {
  let purchasedItemIds = [];

  if (cookieStore?.get('accesstoken')) {
    purchasedItemIds = await getPurchasedItemHistory(cookieStore);
  }

  Object.keys(data).forEach((key: string) => {
    if (key === 'items' || key === 'tagItems') {
      data[key] = data[key].map((item: PurcahsedItemResponse) => {
        const isPurchased = purchasedItemIds?.includes(item.id);

        return { ...item, isPurchased };
      });
    } else if (key === 'item') {
      const isPurchased = purchasedItemIds?.includes(data[key].id);

      data[key] = { ...data[key], isPurchased };
    }
  });

  return data;
};

export const getAdvancedSearchResult = async (
  query: advancedQueryType
): Promise<any> => {
  try {
    const appendUrl = createAdvancedQuery(query);

    const response = (await requestor.get(
      `/listing-v2/items?${appendUrl}`
    )) as any;

    const { data, statusCode }: any = response ?? {};

    if (statusCode === 404 || !data) {
      throw new Error('No data found');
    }

    return data;
  } catch (error: any) {
    try {
      return await getAdvancedSearch({
        ...query,
        name: query.category,
        category: '',
      });
    } catch (error) {
      console.log(error);
      throw new Error('unhandled erro');
    }
  }
};

export const getAdvancedSearch = async (
  query: advancedQueryType
): Promise<any> => {
  try {
    const appendUrl = createAdvancedQuery(query);

    const response = (await requestor.get(
      `/listing-v2/items?${appendUrl}`
    )) as any;

    const { data, statusCode }: any = response ?? {};

    if (statusCode === 404 || !data) {
      throw new Error('No data found');
    }

    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
