'use client';
import React from 'react';

import {
  StyledProductCardImage,
  StyledProductCardWrapper,
  StyledVideoProductVideo,
} from './style';

type Props = {
  imageBaseURL: string;
  alt: string;
  videoBaseURL: string;
  preview_video: string;
  thumbnailBaseURL?: string; // Optional thumbnail
  width?: string;
  height?: string;
  handleOnPlayerClick: () => void;
  imageLoading?: 'eager' | 'lazy';
};

const Player = ({
  imageBaseURL,
  videoBaseURL,
  alt,
  preview_video: previewVideo,
  handleOnPlayerClick,
  imageLoading,
}: Props) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const type = previewVideo?.split('.')?.[1];

  // Example Base64 Image for BlurDataURL
  const base64Placeholder = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAA70lEQVR4AQXBTUvCcADA4Z/7z9xcmbEpdSg6CBYGRUcr6WJvUESfqHN07Jt47hBdBV/AFg2FYTGd2DZzmJiz54ndnpUX51cldvcvSWoqufwGcVlgmlX01CpLmsbD4xMiZ2zeS8MQNRgQX1Gweg6/owGvLxUyxjq2+4VtO8haPIGKxKfdxfpzmUgKruMSxX4QiqDfs2i1A+RwMmM+hsr4nbSvcnNaZHnqE8yTNDpvROGU7FYSueX3MUcuqbUExYsd9jICY66yyOrUm22aHY/vmYec304jRIRuaJROjmg8V/G8IXflawoHhxx/1KjVu/wDycJbrJ7yuz0AAAAASUVORK5CYII=`;

  return (
    <StyledProductCardWrapper
      elevation={3}
      onClick={handleOnPlayerClick}
      sx={
        previewVideo
          ? {
              '&:hover': {
                '& .video-player': {
                  display: 'block',
                  cursor: 'pointer',
                },
                '& .product-image': {
                  display: 'none',
                  cursor: 'pointer',
                },
              },
            }
          : {
              '&:hover': {
                cursor: 'pointer',
              },
            }
      }
    >
      <StyledProductCardImage
        unoptimized
        loading={imageLoading ? imageLoading : 'lazy'}
        className="product-image"
        src={imageBaseURL}
        alt={alt}
        width={0}
        height={0}
        placeholder="blur"
        blurDataURL={base64Placeholder} // Use base64 as fallback
        onMouseEnter={() => setIsHovering(true)}
        onMouseOver={() => setIsHovering(true)}
        onTouchStart={() => setIsHovering(true)}
      />
      {!!previewVideo && isHovering && (
        <StyledVideoProductVideo
          className="video-player"
          autoPlay
          loop
          muted={true}
          controls={false}
          onMouseLeave={() => setIsHovering(false)}
          onTouchEnd={() => setIsHovering(false)}
          poster={imageBaseURL}
        >
          <source src={videoBaseURL} type={`video/${type ?? 'webm'}`} />
        </StyledVideoProductVideo>
      )}
    </StyledProductCardWrapper>
  );
};

export default Player;
