// Declare the global Window interface with Clarity
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    clarity?: (
      _method: string,
      _eventName: string,
      _eventValue: string
    ) => void;
  }
}

export function setClarityEvent(eventName: string, eventValue: string) {
  if (window && typeof window !== 'undefined' && window?.clarity) {
    window.clarity('set', eventName, eventValue);
  }
}
