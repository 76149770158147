import { FileUrl } from './item-card';
import { Category, ItemTag, Mastercategory } from './single-item';

/* eslint-disable no-unused-vars */
export interface FullItem {
  imageUrl: string;
  is_active: boolean;
  seoKeywords: string;
  videoUrl: string;
  createdAt: Date;
  updatedAt: Date;
  author_name: string;
  author_id: string;
  category_id: ICategory[];
  mastercategory_id: string[];
  tags: ITag[];
  name: string;
  description: string;
  previewVideo: string;
  id: string;
  type: ItemType;
  slug: string;
  previewType: ItemPreviewType;
  previewImg: string;
  shortDesc: string;
  seoDesc: string;
  attributes: Attribute[];
  price: string;
  authorId: string;
}
export interface ITag {
  id: string;
  name: string;
  description?: string;
  slug: string;
  seo_title?: string;
  seo_description?: string;
}
export interface ICategory {
  id: string;
  name: string;
  description?: string;
  slug: string;
  seo_title?: string;
  seo_description?: string;
  tagIds: string[];
  is_active?: number;
}
export interface Attribute {
  value: string;
  label: string;
}
export enum ItemType {
  FREE = 'FREE',
  PRO = 'PRO',
  PREMIUM = 'PREMIUM',
}
export enum ItemPreviewType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  YOUTUBE = 'YOUTUBE',
}

export interface PurcahsedItemResponse {
  file_url: FileUrl;
  short_description: string;
  preview_img: string;
  created_at: string;
  type: string;
  video_url: string;
  category_id: string;
  updated_at: string;
  is_voucher_applicable: number;
  price: string;
  preview_type: string;
  mastercategory: Mastercategory;
  currency: string;
  id: string;
  slug: string;
  new_id: number;
  is_active: boolean;
  seo_desc: string;
  itemTags: ItemTag[];
  image_url: string;
  users: User;
  name: string;
  preview_video: string;
  attributes: Attribute[];
  author_id: string;
  category: Category;
  seo_keywords: string;
  converted_price: number;
  discount_price: number;
  symbol: string;
  isPurchased: boolean;
}

interface User {
  last_name: string;
  id: string;
  first_name: string;
  username: string | null;
}
