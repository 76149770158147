import createSvgIcon from '@mui/material/utils/createSvgIcon';

const DownloadEligible = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2479 1.38204C17.2945 0.489977 18.6248 0 20 0C21.3752 0 22.7055 0.489977 23.7521 1.38204L26.2028 3.47213C26.512 3.73585 26.8967 3.89511 27.3018 3.92717L30.5121 4.18361C31.8823 4.29331 33.1687 4.88738 34.1406 5.85936C35.1126 6.83133 35.7067 8.11768 35.8164 9.48787L36.0728 12.7001C36.1053 13.1046 36.2645 13.4885 36.5279 13.7972L38.618 16.2479C39.51 17.2945 40 18.6248 40 20C40 21.3752 39.51 22.7055 38.618 23.7521L36.5279 26.2028C36.2642 26.512 36.1049 26.8967 36.0728 27.3018L35.8164 30.5121C35.7067 31.8823 35.1126 33.1687 34.1406 34.1406C33.1687 35.1126 31.8823 35.7067 30.5121 35.8164L27.2999 36.0728C26.8954 36.1053 26.5115 36.2645 26.2028 36.5279L23.7521 38.618C22.7055 39.51 21.3752 40 20 40C18.6248 40 17.2945 39.51 16.2479 38.618L13.7972 36.5279C13.488 36.2642 13.1033 36.1049 12.6982 36.0728L9.48787 35.8164C8.11768 35.7067 6.83133 35.1126 5.85936 34.1406C4.88738 33.1687 4.29331 31.8823 4.18361 30.5121L3.92717 27.2999C3.89471 26.8954 3.73547 26.5115 3.47213 26.2028L1.38204 23.7521C0.489977 22.7055 0 21.3752 0 20C0 18.6248 0.489977 17.2945 1.38204 16.2479L3.47213 13.7972C3.73585 13.488 3.89511 13.1033 3.92717 12.6982L4.18361 9.48787C4.29331 8.11768 4.88738 6.83133 5.85936 5.85936C6.83133 4.88738 8.11768 4.29331 9.48787 4.18361L12.7001 3.92717C13.1046 3.89471 13.4885 3.73547 13.7972 3.47213L16.2479 1.38204ZM27.1476 17.5069C27.4988 17.1433 27.6931 16.6562 27.6887 16.1507C27.6843 15.6451 27.4816 15.1615 27.1241 14.8041C26.7666 14.4466 26.283 14.2438 25.7774 14.2394C25.2719 14.235 24.7848 14.4293 24.4212 14.7806L18.0719 21.1299L15.5788 18.6368C15.2152 18.2856 14.7281 18.0913 14.2226 18.0956C13.717 18.1 13.2334 18.3028 12.8759 18.6603C12.5184 19.0178 12.3157 19.5014 12.3113 20.0069C12.3069 20.5125 12.5012 20.9995 12.8524 21.3632L16.7087 25.2194C17.0703 25.5809 17.5606 25.784 18.0719 25.784C18.5831 25.784 19.0735 25.5809 19.4351 25.2194L27.1476 17.5069Z"
      fill="#30C90A"
    />
  </svg>,
  'DownloadEligible'
);

export default DownloadEligible;
