import React, { FC, Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import type { Theme } from '@mui/material';
import { Dialog, Drawer, IconButton, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import useDialougeToggler from 'hooks/useDialougeToggler';

export const Wrapper = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Header = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'relative',
}));

export const Content = styled('div')({
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  flex: 1,
});

export const BottomAction = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: '1rem',
  borderTop: `1px solid ${theme.palette.divider}`,
}));

interface Props {
  dialogOpen: boolean;
  toggleDialog: () => void;
  children?: React.ReactNode;
  headerChild?: React.ReactNode;
  bottomActionChild?: React.ReactNode;
  wrapperStyle?: React.CSSProperties;
  wrapperStyleMobile?: React.CSSProperties;
  closeBtn?: boolean;
}

export const DialogDrawer: FC<Props> = (props) => {
  const {
    dialogOpen,
    toggleDialog,
    children,
    headerChild,
    bottomActionChild,
    wrapperStyle,
    wrapperStyleMobile,
    closeBtn,
  } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme?.breakpoints.down('sm')
  );
  const { state, dispatch } = useDialougeToggler();

  return (
    <Fragment>
      {!isMobile ? (
        <Dialog
          scroll="body"
          open={dialogOpen}
          onClose={toggleDialog}
          sx={{
            zIndex: 9999,
          }}
          maxWidth="md"
        >
          <Wrapper sx={wrapperStyle}>
            {((state.toggle && state.toggle.closeBtn) || closeBtn) && (
              <IconButton
                aria-label="close"
                onClick={() => {
                  dispatch({
                    type: 'TOGGLE',
                    payload: { toggled: false, element: '', closeBtn: false },
                  });
                  toggleDialog && toggleDialog();
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 100000,
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <Content>{children}</Content>
          </Wrapper>
        </Dialog>
      ) : (
        <Drawer anchor="bottom" open={dialogOpen} onClose={toggleDialog}>
          <Wrapper sx={wrapperStyleMobile}>
            {((state.toggle && state.toggle.closeBtn) || closeBtn) && (
              <IconButton
                aria-label="close"
                onClick={() => {
                  dispatch({
                    type: 'TOGGLE',
                    payload: { toggled: false, element: '', closeBtn: false },
                  });
                  toggleDialog && toggleDialog();
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 100000,
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            {headerChild && <Header>{headerChild}</Header>}
            <Content>{children}</Content>
            {bottomActionChild && (
              <BottomAction>{bottomActionChild}</BottomAction>
            )}
          </Wrapper>
        </Drawer>
      )}
    </Fragment>
  );
};
