import createSvgIcon from '@mui/material/utils/createSvgIcon';

const LimitExhaused = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="45"
    viewBox="0 0 48 45"
    fill="none"
  >
    <g filter="url(#filter0_d_752_4363)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8016 3.51462C21.4782 -1.16875 26.5485 -1.17753 29.2109 3.5242L29.2867 3.66871L42.9797 27.9718C45.6132 32.4747 42.8859 37.0543 37.8101 36.9904V37H9.74461C9.6743 37 9.60555 36.9968 9.5368 36.9912C4.979 36.9281 2.76807 32.6623 4.70088 28.4612C4.73134 28.3933 4.76494 28.3279 4.80087 28.2648L4.79775 28.2624L18.8055 3.51702L18.8016 3.51462ZM22.7328 27.658C23.0657 27.3283 23.4633 27.1638 23.8617 27.1343C24.1274 27.1151 24.393 27.1574 24.6383 27.2524C24.8828 27.3474 25.1078 27.4943 25.2938 27.6844C25.8344 28.236 25.975 29.072 25.6563 29.773C25.4461 30.236 25.1031 30.5474 24.7102 30.7135C24.4735 30.8149 24.218 30.8604 23.9633 30.854C23.7078 30.8476 23.4563 30.7885 23.2282 30.6799C22.836 30.4939 22.5016 30.1666 22.3141 29.7059C22.1071 29.1981 22.1368 28.6712 22.3633 28.177C22.4532 27.9806 22.5782 27.7929 22.7328 27.658ZM25.3781 23.9C25.3156 25.4896 22.6821 25.4912 22.6219 23.8992C22.3555 21.1767 21.6743 15.0745 21.6961 12.5157C21.718 11.7277 22.3578 11.2606 23.1758 11.0826C23.4282 11.0275 23.7055 11.0003 23.9844 11.0011C24.2657 11.0019 24.5438 11.0299 24.7961 11.085C25.6414 11.2686 26.3047 11.7548 26.3047 12.558L26.3008 12.6379L25.3781 23.9Z"
        fill="#D50000"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_752_4363"
        x="0"
        y="0"
        width="48"
        height="45"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_752_4363"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_752_4363"
          result="shape"
        />
      </filter>
    </defs>
  </svg>,
  'LimitExhaused'
);

export default LimitExhaused;
