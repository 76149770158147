import React from 'react';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const RadioGroupOption = () => {
  const [value, setValue] = React.useState('standard');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="license"
        name="license"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="extended"
          control={<Radio />}
          label={'Extended license $60'}
        />
        {value === 'extended' && (
          <ul style={{ paddingLeft: '20px', listStyle: 'disc' }}>
            <li>Get updates for 2 Month</li>
            <li>Commercial Usage up to 3 times</li>
            <li>24/7 Support For 2 Months</li>
          </ul>
        )}
        <FormControlLabel
          value="standard"
          control={<Radio />}
          label="Standard license $16"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupOption;
