export const getIpData = async (ip: string) => {
  const response = await fetch(
    `${process.env.API_BASE_URL}/users/v2/get-user-location?ip=${
      process.env.ENV === 'dev' ? '100.42.192.0' : ip
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  const data = await response.json();

  return data;
};

export const getIpdataV1 = async () => {
  const response = await fetch(
    `${process.env.API_BASE_URL}/users/get-user-location`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  const data = await response.json();

  return data;
};
