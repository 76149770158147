import { AnchorHTMLAttributes, CSSProperties, FC } from 'react';

import clsx from 'clsx';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import styled from '@mui/material/styles/styled';

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: number }>(({ theme, active }) => ({
  position: 'relative',
  transition: 'color 150ms ease-in-out',
  color: active ? theme.palette.primary.main : theme.palette.grey[800],
  whiteSpace: 'nowrap',
  '&:hover': { color: `${theme.palette.primary.main} !important` },
}));

export interface NavLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  style?: CSSProperties;
  className?: string;
  activeColor?: string;
  prefetch?: boolean;
}

const NavLink: FC<NavLinkProps> = ({
  href,
  children,
  style,
  className,
  prefetch = true,
  ...props
}) => {
  const pathname = usePathname();

  const checkRouteMatch = () => {
    if (href === '/') {
      return pathname === href;
    }

    return pathname?.includes(href);
  };

  return (
    <StyledLink
      href={href}
      style={style}
      className={clsx(className)}
      active={checkRouteMatch() ? 1 : 0}
      prefetch={prefetch}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default NavLink;
