import { useState } from 'react';

import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { useRouter } from 'next/navigation';

import { DownloadAPIResponse } from 'libs/dt-types/response-types/file-download';
import { TimeRemainingResponse } from 'libs/dt-types/response-types/subscription-info';
import { requestor } from 'libs/helpers/api/requestor';
import { ItemDetails } from 'models/license';
import useDialougeToggler from './useDialougeToggler';
interface DownloadItemDetails {
  fileLink: string;
  orderItemId: string;
}

export const fetchUserData = async () => {
  const result = await requestor.get<TimeRemainingResponse>(
    'orders-service/download/time-remaining'
  );

  return result;
};

const directDownload = (data: DownloadItemDetails, itemName: string) => {
  try {
    if (data?.fileLink) {
      const link = document.createElement('a');

      link.href = data?.fileLink;
      link.download = itemName;
      link.target = '_blank';
      document.body.appendChild(link);

      link.click();
      document?.body?.removeChild(link);
    }

    const toastText = `If downloads are not started automatically, please click on this to copy link and paste it in new tab.`;

    toast.success(toastText, {
      position: 'bottom-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      bodyStyle: {
        width: 'fit-content',
      },
      autoClose: false,
      style: {
        backgroundColor: 'rgb(63 146 82)',
        color: '#fff',
      },
      onClick: () => {
        navigator.clipboard.writeText(data?.fileLink ?? '');
      },
    });
  } catch (e) {
    toast.error(
      'Please ensure pop-ups are enabled to download the file successfully.If downloads are not started automatically, please click on this to copy link and paste it in new tab',
      {
        position: 'bottom-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#dc3545',
          color: '#fff',
        },
        autoClose: false,
        onClick: () => {
          navigator.clipboard.writeText(data?.fileLink ?? '');
        },
      }
    );
  }
};

export const downloadClickResponse = async (itemId: string) => {
  const result = await requestor.post<DownloadAPIResponse>(
    'orders-service/download/download-v2',
    {
      itemId,
    }
  );

  return result;
};

export const downloadClickResponseForPurchased = async (itemId: string) => {
  const result = await requestor.post<DownloadAPIResponse>(
    `orders-service/orderitem/getExistingOrderItem`,
    {
      itemId,
    }
  );

  return result;
};

export const createPurchaseOrder = async (
  itemId: string,
  amount: string,
  currency: string,
  itemName: string
) => {
  const result = await requestor.post<any>('orders-service/order/v2', {
    itemId,
    currency,
    itemName,
    amount,
    ['callback_url']: window.location.origin + '/paymentStatus',
  });

  return result;
};

const useDownload = () => {
  const accesstoken = Cookies.get('accesstoken');

  const { push } = useRouter();
  const { data, isLoading, error, refetch } = useQuery(
    ['userData', accesstoken],
    fetchUserData,
    {
      enabled: !!accesstoken,
      cacheTime: 1000 * 15,
      onError: (err: any) => {
        if (err?.response?.status === 401) {
          Cookies.remove('accesstoken');
        }
      },
    }
  );
  const [isDownloadLoading, setisDownloadLoading] = useState(false);

  const { dispatch } = useDialougeToggler();

  const notLoggedInHandler = () => {
    dispatch({
      type: 'TOGGLE',
      payload: {
        toggled: true,
        element: 'LoginForm',
        closeBtn: true,
      },
    });
  };

  const onDownloadHandler = async (
    itemId: string,
    itemName: string,
    _?: ItemDetails | undefined,
    isPurchased?: boolean | undefined
  ) => {
    if (!accesstoken) {
      notLoggedInHandler();
    } else {
      try {
        setisDownloadLoading(true);

        let data;

        if (isPurchased) {
          data = (await downloadClickResponseForPurchased(itemId))?.data;
        } else {
          data = (await downloadClickResponse(itemId))?.data;
        }

        if (data?.fileLink) {
          directDownload(data, itemName);
          setisDownloadLoading(false);
        }

        setisDownloadLoading(false);
      } catch (e) {
        setisDownloadLoading(false);

        return;
      }

      setTimeout(() => {
        refetch();
      }, 1000);
    }
  };

  const purchaseHandler = async (
    itemId: string,
    amount: string,
    currency: string,
    itemName: string
  ): Promise<void> => {
    if (!accesstoken) {
      notLoggedInHandler();
    } else {
      setisDownloadLoading(true);

      const orderData = await createPurchaseOrder(
        itemId,
        amount,
        currency,
        itemName
      );

      if (orderData?.data?.url) {
        push(orderData?.data?.url);
      }

      setisDownloadLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    onDownloadHandler,
    purchaseHandler,
    isDownloadLoading,
    isLoggedIn: !!accesstoken,
    notLoggedInHandler,
    accesstoken,
  };
};

export default useDownload;
