/* eslint-disable @next/next/no-img-element */

import Link from 'next/link';

import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Skeleton, Typography } from '@mui/material';

import { H2, Small } from 'libs/components/typography';
import { ActionButton, InnerBox } from './styles';

interface Props {
  heading: string;
  headingFocusText: string;
  buttonText: string;
  additionalText: string;
  additionalLinkText: string;
  additionalLink: string;
  handleClick?: () => void;
}

const CommonHeading = ({
  heading,
  headingFocusText,
}: {
  heading: string;
  headingFocusText: string;
}) => (
  <H2 sx={{ fontSize: '22px', fontWeight: '600', textAlign: 'center' }}>
    {heading}
    <span
      style={{
        background: 'linear-gradient(90deg, #6E5BE4 45.91%, #FF4790 87.86%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {' '}
      {headingFocusText}
    </span>
  </H2>
);

export const FreeUserCTA = ({
  heading,
  headingFocusText,
  buttonText,
  additionalText,
  additionalLinkText,
  additionalLink,
  handleClick,
}: Props) => (
  <InnerBox>
    <CommonHeading heading={heading} headingFocusText={headingFocusText} />
    <Box py={1.5}>
      <ActionButton
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClick}
      >
        {buttonText}
      </ActionButton>
    </Box>
    {!!additionalText && (
      <Small>
        {additionalText} <Link href={additionalLink}>{additionalLinkText}</Link>
      </Small>
    )}
  </InnerBox>
);

export const DownloadEligibleCTA = ({
  exhausted,
  downloadFile,
  limitExceededText,
}: any) => (
  <InnerBox sx={{ display: exhausted ? 'none' : 'flex' }}>
    <CommonHeading
      heading={
        'Gratitude in every byte! Thanks for subscribing and being a vital part of our'
      }
      headingFocusText="community"
    />
    <Box py={2}>
      <img
        src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/svg/product/raising-hands.svg`}
        alt="raising hands"
      />
    </Box>
    <Small
      sx={{
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        textAlign: 'center',
        py: 1,
      }}
    >
      {limitExceededText}{' '}
      <Link href="/terms-of-use#unlimited-download-policy">
        <InfoOutlinedIcon
          fontSize="inherit"
          sx={{ marginLeft: '0.1rem', cursor: 'pointer' }}
        />
      </Link>
    </Small>
    <Box py={2}>
      <ActionButton
        variant="contained"
        color="primary"
        fullWidth
        onClick={downloadFile}
        disabled={exhausted}
      >
        Download
      </ActionButton>
    </Box>
    <Small
      sx={{
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        textAlign: 'center',
      }}
    >
      License Included
      <Link href="/licence">
        <InfoOutlinedIcon
          fontSize="inherit"
          sx={{ marginLeft: '0.3rem', cursor: 'pointer' }}
        />
      </Link>
      <Typography
        sx={{
          fontSize: '11px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          textAlign: 'center',
          marginTop: '0.5rem',
          color: '#65E',
        }}
      >
        Please ensure pop-ups are enabled to download the file successfully.
      </Typography>
    </Small>
  </InnerBox>
);

export const PurchasedItemCTA = ({
  downloadFile,
  currency,
  discountPrice,
  isPurchased,
}: any) => (
  <InnerBox sx={{ display: 'flex' }}>
    <CommonHeading
      heading={
        'Gratitude in every byte! Thanks for purchasing this template and being a vital part of our'
      }
      headingFocusText="community"
    />
    <Box py={2}>
      <img
        src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/svg/product/raising-hands.svg`}
        alt="raising hands"
      />
    </Box>
    <Box py={1}>
      <ActionButton
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          downloadFile(
            discountPrice,
            currency,
            undefined,
            undefined,
            isPurchased
          );
        }}
      >
        Download
      </ActionButton>
    </Box>
    <Small
      sx={{
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        textAlign: 'center',
      }}
    >
      License Included
      <Link href="/licence">
        <InfoOutlinedIcon
          fontSize="inherit"
          sx={{ marginLeft: '0.3rem', cursor: 'pointer' }}
        />
      </Link>
    </Small>
    <Typography
      sx={{
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        textAlign: 'center',
        marginTop: '0.5rem',
        color: '#65E',
      }}
    >
      <AnnouncementRoundedIcon
        sx={{
          verticalAlign: 'middle',
          cursor: 'pointer',
          fontSize: '0.8rem',
        }}
      />
      Please ensure pop-ups are enabled to download the file successfully.
    </Typography>
  </InnerBox>
);

export const ProMemberLimitExceed = () => {
  return (
    <InnerBox>
      <CommonHeading
        heading="Upgrade to premium Subscription and get access to "
        headingFocusText="Exclusive Content"
      />

      <Box py={2}>
        <Link href="/pricing">
          <ActionButton variant="contained" color="primary" fullWidth>
            Upgrade Plan
          </ActionButton>
        </Link>
      </Box>

      <Small>
        Pay Addition cost and upgrade to get Premium files.
        {'  '}
        <Link href={'/pricing'}>Learn more</Link>
      </Small>
    </InnerBox>
  );
};

export const CTASkelton = () => {
  return (
    <InnerBox sx={{ height: '15rem' }}>
      <Typography
        component="h2"
        sx={{ fontSize: '22px', fontWeight: '600', textAlign: 'center' }}
      >
        <Skeleton
          width={130}
          animation="pulse"
          sx={{ bgcolor: 'grey.500', marginInline: 'auto' }}
        />
        <Skeleton
          width={230}
          animation="pulse"
          sx={{ bgcolor: 'grey.500', marginInline: 'auto' }}
        />
        <Skeleton
          sx={{ bgcolor: 'grey.500', marginInline: 'auto' }}
          animation="pulse"
          width={130}
        />
      </Typography>
      <Box py={1.5}>
        <Skeleton sx={{ bgcolor: 'grey.500' }} animation="pulse" width={80} />
      </Box>
    </InnerBox>
  );
};
