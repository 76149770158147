'use client';

import { createContext, PropsWithChildren, useMemo, useReducer } from 'react';

type InitialState = { toggle: DialougeType };
export type DialougeType = {
  toggled: boolean;
  element: string;
  closeBtn?: boolean;
};

type ToggleActionType = {
  type: 'TOGGLE';
  payload: DialougeType;
};

const INITIAL_STATE = { toggle: null };

interface ContextProps {
  state: InitialState;
  dispatch: (_args: ToggleActionType) => void;
}

export const ToogleDialog = createContext<ContextProps>({} as ContextProps);

const reducer = (state: InitialState, action: ToggleActionType) => {
  switch (action.type) {
    case 'TOGGLE':
      return { toggle: action.payload };

    default: {
      return state;
    }
  }
};

const ToogleDialogProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer<any>(reducer, INITIAL_STATE);

  const toggle = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ToogleDialog.Provider value={toggle as any}>
      {children}
    </ToogleDialog.Provider>
  );
};

export default ToogleDialogProvider;
