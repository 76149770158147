export interface IndexedDBData {
  fileId: string;
  receivedBytes: number;
  totalBytes: number;
  chunks: string[];
  status: string;
  fileName: string;
  previewImage: string;
  fileUrl: string;
}

export const openDB = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open('downloadDB', 1);

    request.onupgradeneeded = () => {
      const db = request.result;

      db.createObjectStore('downloads', { keyPath: 'fileId' });
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = (error) => reject(error);
  });
};

export const saveProgressToDB = async (
  fileId: string,
  receivedBytes: number,
  totalBytes: number,
  chunks: Uint8Array[],
  status: string
) => {
  const db = await openDB();
  const tx = db.transaction('downloads', 'readwrite');
  const store = tx.objectStore('downloads');
  const prevData = await store.get(fileId);

  store.put({
    ...prevData,
    fileId,
    receivedBytes,
    totalBytes,
    chunks,
    status,
  });

  return new Promise((resolve) => {
    tx.oncomplete = () => resolve('done');
  });
};

export const getProgressFromDB = async (fileId: string) => {
  const db = await openDB();
  const tx = db.transaction('downloads', 'readonly');
  const store = tx.objectStore('downloads');

  const data = await store.get(fileId);

  return new Promise<IndexedDBData>((resolve) => {
    data.onsuccess = () => resolve(data.result);
  });
};

export const deleteProgressFromDB = async (fileId: string) => {
  const db = await openDB();
  const tx = db.transaction('downloads', 'readwrite');

  tx.objectStore('downloads').delete(fileId);

  return tx;
};

export const getAllDownloads = async () => {
  const db = await openDB();
  const tx = db.transaction('downloads', 'readonly');
  const store = tx.objectStore('downloads');
  const data = store.getAll();

  return new Promise<IndexedDBData[]>((resolve) => {
    data.onsuccess = () => resolve(data.result);
  });
};

export const deleteAllDataFromDB = async () => {
  const db = await openDB();
  const tx = db.transaction('downloads', 'readwrite');
  const store = tx.objectStore('downloads');

  store.clear();

  return new Promise((resolve) => {
    tx.oncomplete = () => resolve('All data deleted');
  });
};
