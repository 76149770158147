import type { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

const FlexBox = ({ children, ...props }: BoxProps) => (
  <Box display="flex" {...props}>
    {children}
  </Box>
);

export default FlexBox;
